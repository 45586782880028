html {
    min-height: 100vh;
}

body {
    min-height: 100vh;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div {
    display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    min-height: 100vh;
}

@font-face { font-family: 'NotoSans'; src: url(./fonts/NotoSansKR-Regular.otf); }
@font-face { font-family: 'NotoSans'; src: url(./fonts/NotoSansKR-Light.otf); font-weight: 300;}
@font-face { font-family: 'NotoSans'; src: url(./fonts/NotoSansKR-Medium.otf); font-weight: 500;}
@font-face { font-family: 'NotoSans'; src: url(./fonts/NotoSansKR-Bold.otf); font-weight: 600;}
@font-face { font-family: 'Roboto'; src: url(./fonts/Roboto-Regular.ttf); }
@font-face { font-family: 'Roboto'; src: url(./fonts/Roboto-Medium.ttf); font-weight: 500; }
@font-face { font-family: 'Ssurround'; src: url(./fonts/Cafe24Ssurround.ttf); }
@font-face { font-family: 'SCDream'; src: url(./fonts/SCDream7.otf); }
@font-face { font-family: 'SCDream'; src: url(./fonts/SCDream5.otf); font-weight: 500;}
@font-face { font-family: 'SCDream'; src: url(./fonts/SCDream6.otf); font-weight: 600;}